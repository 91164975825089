<template>
  <div feature-slide v-editable="blok">
    <div class="feature-content">
      <div class="feature-label" v-if="!blok?.hide_label_on_slide" v-text="blok.label"></div>
      <h3 class="feature-title" v-if="blok?.title && title != ''" v-html="title"></h3>
      <div class="feature-description" v-if="blok?.description && description != ''" v-html="description"></div>
      <div v-if="blok.buttons" class="feature-buttons">
        <StoryblokComponent v-for="blok in blok.buttons" :key="blok._uid" :blok="blok" />
      </div>
    </div>
    <div class="feature-image">
      <NuxtImg provider="storyblok" loading="lazy" v-if="blok.image" :src="blok.image.filename" :alt="`Feature Image ${blok.image.alt}`" :width="dimensions.width" :height="dimensions.height" />
    </div>
  </div>
</template>
<style src="~/assets/scss/section/feature-slide.scss" lang="scss"></style>
<script setup>
  const props = defineProps({ blok: Object });

  const title = computed(() => useRichText(props?.blok?.title || {}));
  const description = computed(() => useRichText(props?.blok?.description || {}));

  const dimensions = computed(() => {
    if (props.blok.image) {
      const urls = props.blok.image?.filename?.split('/')[5].split('x');
      return {
        width: urls[0],
        height: urls[1]
      };
    }
    return { width: 0, height: 0 };
  });
</script>
